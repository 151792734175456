import React from 'react';
import ClickableResult from './ClickableResult';
import { v4 as uuidv4 } from 'uuid'
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material'

const ResultList = (props) => {

    const {displayResults, setClickedId, setEditLicenseOpen} = props

    return (
        <div style={{display: 'flex', justifyContent:'center'}}>
            <Table style={{width:'85vw'}}>
            <TableHead>
                <TableRow style={{background:'#CCCCCC'}}>
                    <TableCell align='center' style={{fontWeight:'bold'}}>ID</TableCell>
                    <TableCell align='center' style={{fontWeight:'bold'}}>Description</TableCell>
                    <TableCell align='center' style={{fontWeight:'bold'}}>System ID</TableCell>
                    <TableCell align='center' style={{fontWeight:'bold'}}>System ID 2</TableCell>
                    <TableCell align='center' style={{fontWeight:'bold'}}>Last Modified</TableCell>
                    <TableCell align='center' style={{fontWeight:'bold'}}>Express</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                    {displayResults.length > 0 ? (
                        displayResults.map(result => {
                            return (
                                <ClickableResult key={uuidv4()} result={result} setClickedId={setClickedId} setEditLicenseOpen={setEditLicenseOpen}/>
                            )
                        })
                    ): <></>}
            </TableBody>
        </Table>
        </div>
    )
}

export default ResultList

