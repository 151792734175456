import { useState } from 'react'
import { TextField, Button } from '@mui/material'
import './login.css'

//import bottom from '../../assets/bottom.gif'
import logo from '../../assets/Phone-Suite-Logo-Color.png'
//import right from '../../assets/PhoneSuiteHeaderRight.jpg'

import axios from 'axios'
import { url } from '../../config'
import md5 from 'md5'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'


const Login = (props) => {

    const { pwdForEdit, loggedIn, userId, setToken, displayName, displayEmail, setLoggedInUserLevel, setCheckSumUsername} = props

    const [uname, setUName] = useState('')
    const [pwd, setPwd] = useState('')
    const [invalid, setInvalid] = useState(false)
    const [errorType, setErrorType] = useState('')

    const onSubmitClick = async (e) => {
        e.preventDefault()

        let result = await axios.post(`${url}/api/auth`, {
            username: uname,
            password: md5(pwd)
        })
        .then(function (response) {
            //console.log(response)
            setToken(response.data.token)
            displayName(response.data.fullname)
            displayEmail(response.data.email)
            setLoggedInUserLevel(response.data.userlev)
            setCheckSumUsername(uname)
            userId(response.data.id)
            return response.data
        })
        .catch(function (error) {
            console.error(error)
            if (String(error).includes('401')) {
                setErrorType('401 username or password incorrect')
            }
            if (String(error).includes('404')) {
                setErrorType('404 Server is unavailable')
            }
        })
        //console.log(result)
        if (result) {
            loggedIn(true)
            pwdForEdit(pwd)
        } else {
            setPwd('')
            setInvalid(true)
        }
    }

    const getUsernameValue = (e) => {
        //console.log(e.target.value)
        setUName(e.target.value)
    }

    const getpasswordValue = (e) => {
        //console.log(e.target.value)
        setPwd(e.target.value)
    }



    return(
        <>
            <div className='Login'>
                <div className='Header'>
                    <img style={{width: '50vw', height:'20vh'}} src={logo} alt='phonesuite'/>
                    {/* <img style={{width: '27.5vw'}} src={right} alt='phonesuite'/> */}
                </div>
                <div className='Fields'>
                    <form onSubmit={onSubmitClick}>
                    <div style={{display:'flex', justifyContent:'center', fontWeight: 'bold', marginBottom: '5px'}}>
                        Licensing System
                    </div>
                    {invalid ? <div className='Error'>{errorType}</div> : <></>}
                    <div>
                        <TextField 
                            style={{marginBottom: '10px'}} 
                            label='Username' 
                            type='search'
                            value={uname}
                            onChange={getUsernameValue}
                        />
                    </div>
                    <div>
                        <TextField 
                            style={{marginBottom: '10px'}} 
                            label='Password' 
                            type='password'
                            value={pwd}
                            onChange={getpasswordValue}
                        />
                    </div>
                    <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                        <Button type='submit' variant='contained' style={{marginRight:'10px'}} onClick={onSubmitClick}>Submit</Button>
                    </div>
                    </form>
                </div>
                <div style={{width:'55vw', background: 'white', display: 'flex', justifyContent: 'center', borderRadius:'20px'}}>
                    {/* <img style={{width: '55vw'}} src={bottom} alt='phonesuite'/> */}
                </div>
            </div>
        </>
    )
}

export default Login