import React from 'react';
import ResultList from '../ResultList/ResultList';

const SearchResults = (props) => {

    const {results, setClickedId, setEditLicenseOpen } = props
    
    return (
        <div>
            <ResultList displayResults={results} setClickedId={setClickedId} setEditLicenseOpen={setEditLicenseOpen}/>
        </div>
    )
}

export default SearchResults

