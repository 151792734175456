import { TableRow, TableCell } from '@mui/material'

const ClickableResult = (props) => {

    const { result, setClickedId } = props
    //console.log(result.last_modified)

    let date = new Date(result.last_modified * 1000).toLocaleString()

    const resultClick = () => {
        //console.log(result.id)
        setClickedId(result)
        props.setEditLicenseOpen(true)
    }

    return (
        <TableRow onClick={resultClick} style={{width:'95vw'}}>
            <TableCell align='center'>{result.id}</TableCell>
            <TableCell align='center'>{result.descr}</TableCell>
            <TableCell align='center'>{result.zend_id}</TableCell>
            <TableCell align='center'>{result.zend_id2}</TableCell>
            <TableCell align='center'>{date}</TableCell>
            <TableCell align='center'>{result.lite ? 'True' : 'False'}</TableCell>
        </TableRow>
    )
}

export default ClickableResult