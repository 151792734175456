import { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    Radio,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField, 
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios'
import { url } from '../../config'
import md5 from 'md5'


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#DDDDDD',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    padding: '2px'
}))

const UsersDisplay = (props) => {

    const { setDisplayName, usersList, token, checkSumUsername, updateUsers, userLevel } = props


    const [hasUsers, setHasUsers] = useState(false)

    const [selectedValue, setSelectedValue] = useState('')
    const [selectedUsername, setSelectedUsername] = useState('')
    
    const [addUserDialog, setAddUserDialog] = useState(false)
    const [addUsername, setAddUsername] = useState('')
    const [addUsernameVal, setAddUserNameVal] = useState('')
    const [addEmail, setAddEmail] = useState('')
    const [isDupe, setIsDupe] = useState(false)
    const [addFullName, setAddFullName] = useState('')
    const [addPassword, setAddPassword] = useState('')

    const [editUserDialog, setEditUserDialog] = useState(false)
    const [editUserName, setEditUserName] = useState('') 
    const [editFullName, setEditFullName] = useState('')
    const [editPassword, setEditPassword] = useState('')
    const [editEmail, setEditEmail] = useState('')
    const [editLevel, setEditLevel] = useState('')

    const [level, setLevel] = useState('')

    

    useEffect(() => {
        //console.log(usersList)
        usersList.length > 0 ? setHasUsers(true) : setHasUsers(false)

        const timer = setTimeout(async () => {
          setAddUsername(addUsernameVal)
          if (addUsername !== '') {
              await axios.get(`${url}/api/user/check/${addUsername}`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(function (response) {
                //console.log(response)
                if (response.data > Number(0)) {
                  setIsDupe(true)
                } else {
                  setIsDupe(false)
                }
              })
              .catch(function (error) {
                console.error(error)
              })
          } else if (addUsername === '') { setIsDupe(false) }
      }, 500)
    
      return () => {
          clearTimeout(timer)
      }
      }, [hasUsers, addUsername, addUsernameVal, token, usersList])

    const handleEditClose = () => {
        setEditUserDialog(false)
        setLevel('')
        setEditFullName('')
        setEditPassword('')
    }

    const handleAddClose = () => {
        setAddUserDialog(false)
        setLevel('')
        setIsDupe(false)
        setAddUserNameVal('')
        setAddUsername('')
        setAddFullName('')
        setAddPassword('')
    }

    const handleChange = (e) => {   
        //console.log(e)
        setSelectedValue(e.target.id)
        setSelectedUsername(e.target.name)
    }

    const deleteClick = async() => {
        //console.log(checkSumUsername)
        if (selectedUsername === checkSumUsername) {
            alert('Cannot delete self. Try again')
        } else {
            await axios.delete(`${url}/api/user/${selectedValue}`, {
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(function (error) {
                console.error(error)
            })

            // RELOAD TABLE
            await axios.get(`${url}/api/user`, {
                headers: {'Authorization': `Bearer ${token}`}
            })
            .then(function (response) {
                updateUsers(response.data)
            })
            .catch(function (error) {
                console.error(error)
            })
        }
    }
    

    const editClick = async () => {
        
        if (selectedValue !== null) {
            await axios.get(`${url}/api/user/${Number(selectedValue)}`, {
                headers: {'Authorization': `Bearer ${token}`}
              })
              .then(function (response) {
                //console.log(response.data[0].password)
                //updateUsers(response.data)
                setEditUserName(response.data[0].username)
                setEditEmail(response.data[0].email)
                setEditFullName(response.data[0].fullname)
                setEditPassword(response.data[0].password)
                setEditLevel(response.data[0].userlev)
              })
              .catch(function (error) {
                console.error(error)
              })
            setEditUserDialog(true)
        }
        
    }

    const addClick = () => {
        setAddUserDialog(true)
    }

    const handleMenuSelect = (e) => {
        //console.log(e)
        setLevel(e.target.value)
        //setEditLevel(e.target.value)
    }

    const onAddNewUserChange = (e) => {
        setAddUserNameVal(e.target.value)
    }

    const onAddEmailChange = (e) => {
        setAddEmail(e.target.value)
    }

    const onAddfullNameChange = (e) => {
        setAddFullName(e.target.value)
    }

    const onAddPasswordChange = (e) => {
        setAddPassword(e.target.value)
    }

    const handleAddNewUserSubmit = async () => {
        await axios.post(`${url}/api/user`, {
            username: addUsername,
            email: addEmail,
            fullname: addFullName,
            userlev: level === 'Admin' ? 2 : 1,
            password: md5(addPassword)
        },
        {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(function (response) {
            //console.log(response)
            return response
        })
        .catch(function (error) {
            console.error(error)
        })

        await axios.get(`${url}/api/user`, {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(function (response){
            console.log(response)
            updateUsers(response.data)
        })
        .catch(function (error) {
            console.error(error)
        })


        handleAddClose()
    }

    const onEditEmailChange = (e) => {
        setEditEmail(e.target.value)
    }

    const onEditfullNameChange = (e) => {
        setEditFullName(e.target.value)
    }

    const onEditPasswordChange = (e) => {
        setEditPassword(e.target.value)
    }

    const handleEditUserSubmit = async() => {
        if (selectedValue !== '') {
            //console.log(editLevel)
            await axios.put(`${url}/api/user/${selectedValue}`, {
            email: editEmail,
            fullname: editFullName,
            userlev: editUserName === checkSumUsername ? null : level === 'Admin' ? 2 : 1,
            password: editPassword === undefined ? null : md5(editPassword)
        },
        {
            headers: {'Authorization': `Bearer ${token}`}
        }).then(function (response) {
            //console.log(response)
            editPassword !== undefined || '' ? alert('Password was changed') : <></>
            setHasUsers(false)
            return response
        })
        .catch(function (error) {
            console.error(error)
            if (String(error).includes('401')) {alert('Cannot change own user level')}
        })

        await axios.get(`${url}/api/user`, {
            headers: {'Authorization': `Bearer ${token}`}
          })
          .then(function (response) {
            //console.log(response)
            
            //setClickedId('')
            updateUsers(response.data)   
            setHasUsers(true)     
          })
          .catch(function (error) {
            console.error(error)
          })

          
          handleEditClose()
               
        } else { alert('Choose someone to edit their credentials')}
    }

    return (
        <>
            <div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
                <h3>User Management</h3>
                <div>
                    <Table>
                        <TableHead>
                            <TableRow style={{backgroundColor:'#CCCCCC'}}>
                                <TableCell style={{padding:'2px'}}></TableCell>
                                <TableCell align='center' style={{fontWeight:'bold', padding:'2px'}}>Username</TableCell>
                                <TableCell align='center' style={{fontWeight:'bold', padding:'2px'}}>Full Name</TableCell>
                                <TableCell align='center' style={{fontWeight:'bold', padding:'2px'}}>User Level</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {hasUsers ? usersList.map(user => {   
                                //console.log(user)   
                                if (user.username === checkSumUsername) {
                                    setDisplayName(user.fullname)
                                }                  
                                return (
                                    <StyledTableRow key={uuidv4()}>
                                        <TableCell>
                                            <Radio 
                                            checked={selectedValue === String(user.id)}
                                            onChange={handleChange} 
                                            value={String(user.id)}
                                            id={String(user.id)}
                                            name={String(user.username)}
                                            size='small'
                                            style={{padding:'2px'}}
                                            />
                                        </TableCell>
                                        <TableCell align='center' style={{padding:'2px'}}>{user.username}</TableCell>
                                        <TableCell align='center' style={{padding:'2px'}}>{user.fullname}</TableCell>
                                        <TableCell align='center' style={{padding:'2px'}}>{user.userlev === 1 ? 'User' : 'Admin'}</TableCell>
                                    </StyledTableRow>
                                )
                            }) : <></> 
                            }
                        </TableBody>
                    </Table>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <Button disabled={userLevel === 2 ? false : true} variant='contained' onClick={deleteClick} style={{margin:'10px'}}>Delete User</Button>
                        <Button variant='contained' onClick={editClick} style={{margin:'10px'}}>Edit User</Button>
                        <Button disabled={userLevel === 2 ? false : true} variant='contained' onClick={addClick} style={{margin:'10px'}}>Add New User</Button>
                    </div>

                    <Dialog open={addUserDialog} onClose={handleAddClose}>
                    <DialogTitle style={{display:'flex', justifyContent:'center', background:'#282c34', color:'white', marginBottom:'5px'}}>Add New User</DialogTitle>
                        <DialogContent>
                            <Grid container direction='column'>
                                <Grid item style={{marginTop:'10px', marginBottom:'10px'}}>
                                    <TextField onChange={onAddNewUserChange} type='input' label='Username'/>
                                    {isDupe ? <div style={{color:'red'}}>username already exists</div> : <></>}
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onAddEmailChange} type='input' label='Email' />
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onAddfullNameChange} type='input' label='Full Name' />
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onAddPasswordChange} type='password' label='Pasword' />
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <FormControl fullWidth>
                                        <InputLabel id='userLevel'>User Level</InputLabel>
                                        <Select value={level !== 'Admin' ? 'User' : 'Admin'} onChange={handleMenuSelect} labelId='userLevel' id='userLevelSelect' label='User Level' style={{width:'200px'}}>
                                            <MenuItem value='Admin'>Admin</MenuItem>
                                            <MenuItem value='User'>User</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item style={{display:'flex', justifyContent:'space-evenly'}}>
                                    <Button onClick={handleAddNewUserSubmit} variant='contained'>Submit</Button>
                                    <Button onClick={handleAddClose} variant='contained'>Cancel</Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={editUserDialog} onClose={handleEditClose}>
                        <DialogTitle style={{display:'flex', justifyContent:'center', background:'#282c34', color:'white', marginBottom:'5px'}}>Edit User</DialogTitle>
                        <DialogContent>
                            <Grid container direction='column'>
                                <Grid item style={{width:'100%', display:'flex', justifyContent:'center'}}>
                                    <h4>{`Username: ${selectedUsername}`}</h4>
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onEditEmailChange} type='input' label='Email' value={editEmail}/>
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onEditfullNameChange} type='input' label='Full Name' value={editFullName} />
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <TextField onChange={onEditPasswordChange} type='password' label='Pasword' />
                                </Grid>
                                <Grid item style={{marginBottom:'10px'}}>
                                    <FormControl fullWidth>
                                        {
                                        editUserName !== checkSumUsername
                                        ? 
                                        <>
                                            <InputLabel id='userLevel'>User Level</InputLabel>
                                            <Select defaultValue={editLevel === 1 ? 'User' : 'Admin' } onChange={handleMenuSelect} labelId='userLevel' id='userLevelSelect' label='User Level' style={{width:'200px'}}>
                                                <MenuItem value='Admin'>Admin</MenuItem>
                                                <MenuItem value='User'>User</MenuItem>
                                            </Select>
                                        </>
                                        :
                                        <></>
                                        }
                                    </FormControl>
                                </Grid>
                                <Grid item style={{display:'flex', justifyContent:'space-evenly'}}>
                                    <Button onClick={handleEditUserSubmit} variant='contained'>Submit</Button>
                                    <Button onClick={handleEditClose} variant='contained'>Cancel</Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </>
    )
}

export default UsersDisplay