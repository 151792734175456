import { useEffect, useState } from 'react'
import fileDownload from 'js-file-download';
import {
    Button,
    TextField,  
    Checkbox,
    FormControlLabel,
    Grid
} from '@mui/material'

import axios from 'axios'
import { url } from '../../config'


const EditLicense = (props) => {
    const { clickedId, setClickedId, token, setEditLicenseOpen, setSearchResults } = props


    //console.log(clickedId)
    let date = new Date(clickedId.swa*1000)
    let utc = date.getTime() + (date.getTimezoneOffset() * 60000)
    date = new Date(utc)
    let valueDate

    const formatDate = () => {
      let month = '' + (date.getMonth() + 1), day = '' + (date.getDate()), year = date.getFullYear()
      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      //console.log([year, month, day].join('-'))
      return [year, month, day].join('-')
    }

    valueDate = formatDate()
    
    //console.log(valueDate)
    

    const [liteChecked, setLiteChecked] = useState(clickedId.lite)
    const [devicesChecked, setDevicesChecked] = useState(clickedId.devices > 0)
    const [spansChecked, setSpansChecked] = useState(clickedId.spans > 0)
    const [nonSipRoomsChecked, setNonSipRoomsChecked] = useState(clickedId.nonsiprooms > 0)
    const [browserConsolesChecked, setBrowserConsolesChecked] = useState(clickedId.browser_consoles > 0)

    const [zendOne, setZendOne] = useState(clickedId.zend_id)
    const [zendTwo, setZendTwo] = useState(clickedId.zend_id2)
    const [description, setDescription] = useState(clickedId.descr)
    const [devices, setDevices] = useState(clickedId.devices)
    const [spans, setSpans] = useState(clickedId.spans)
    const [nonsiprooms, setNonsiprooms] = useState(clickedId.nonsiprooms)
    const [browserConsoles, setBrowserConsoles] = useState(clickedId.browser_consoles)
    const [swa, setSwa] = useState(clickedId.swa)
    const [lite, setLite] = useState(clickedId.lite)

    const [challengeString, setChallengeString] = useState('')
    const [challengeResponse, setChallengeResponse] = useState('')

    useEffect(() => {
      setSwa(valueDate)
    }, [valueDate, setSwa])

    const handleLiteChecked = (e) => {
      //console.log(e.target.checked)
      setLiteChecked(!liteChecked)
      setLite(!lite)
    }

    const handledevicesChecked = (e) => {
      setDevicesChecked(!devicesChecked)
    }

    const handlespansChecked = (e) => {
      setSpansChecked(!spansChecked)
    }

    const handlenonSipRoomsChecked = (e) => {
      setNonSipRoomsChecked(!nonSipRoomsChecked)
    }

    const handlebrowserConsolesChecked = (e) => {
      setBrowserConsolesChecked(!browserConsolesChecked)
    }

    const handleDescrChange = (e) => {
      setDescription(e.target.value)
      //console.log(description)
    }

    const handleZendOneChange = (e) => {
      setZendOne(e.target.value)
    }

    const handleZendTwoChange = (e) => {
      setZendTwo(e.target.value)
    }

    const handleDevicesChange = (e) => {
      if (liteChecked) {
        setDevices(0)
      } else {
        setDevices(Number(e.target.value))
      }
    }

    const handleSpansChange = (e) => {
      if (liteChecked) {
        setSpans(0)
      } else {
        setSpans(Number(e.target.value))
      }
    }

    const handleNonSipRoomsChange = (e) => {
      if (liteChecked)  {
        setNonsiprooms(0)
      } else {
        setNonsiprooms(Number(e.target.value))
      }
    }

    const handleBrowserconsolesChange = (e) => {
      setBrowserConsoles(e.target.value)
    }
    
    const handleSwaChange = (e) => {
      //console.log(e.target.value)
      setSwa(e.target.value)
    }


    const handleChallengeChange =(e) => {
      setChallengeString(e.target.value)
    }

    const downloadClick = async() => {
        await axios.get(`${url}/api/license/download/${clickedId.id}`, {
            headers: {'Authorization': `Bearer ${token}`}
          })
          .then(function (response) {
            //console.log(response.data)
            fileDownload(response.data, 'license.bin')
          })
          .catch(function (error) {
            console.error(error)
          })
    }

    const deleteClick = async () => {
      await axios.delete(`${url}/api/license/${clickedId.id}`, {
        headers: {'Authorization': `Bearer ${token}`}
      })
      .then(function (error) {
        console.error(error)
      })
      setEditLicenseOpen(false)
      setSearchResults([])
    }

    const saveLicenseClick = async () => {
      // if (isNaN(swa)) {
      //   console.log(clickedId.swa)
      //   console.log(swa)
      // }
      //if (isNaN(swa) || swa === null) setSwa(clickedId.swa)
      await axios.put(`${url}/api/license/${clickedId.id}`, {
          zend_id: zendOne,
          zend_id2: zendTwo,
          descr: description,
          devices: devices,
          spans: spans,
          nonsiprooms: nonsiprooms,
          browserconsoles: browserConsoles,
          swa: Date.parse(swa)/1000,
          lite: lite
        },
        {headers: {
          'Authorization': `Bearer ${token}`
        }}         
      )
      .then(function (response) {
        //console.log(response)
        alert('License Saved')
        //console.log(response)
        setSwa('')
        return response
      })
      .catch(function (error) {
        console.error(error)
        if (error) alert('Something went wrong while saving your license. Please try again.')
      })

      await axios.get(`${url}/api/license/${clickedId.id}`, {
        headers: {'Authorization': `Bearer ${token}`}
      })
      .then(function (response) {
        //console.log(response)
        //setEditLicenseOpen(false)
        setClickedId(response.data[0])
        setSearchResults([response.data[0]])
        //setSwa(response.data[0].swa)
        setEditLicenseOpen(true)
      })
      //setEditLicenseOpen(false)
      
    }

    const handleResetOne = async () => {
      if (challengeString !== '') {
        await axios.post(`${url}/api/challenger/${clickedId.id}`,{
          challenge: `${challengeString}`
        },
        {
          headers: 
            {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
          //console.log(response)
          setChallengeResponse(response.data.response)
        })
        .catch(function (error) {
          console.error(error)
        })
      }
    }

    const handleResetTwo = async () => {
      await axios.post(`${url}/api/challenger/${clickedId.id}`,{
          challenge: challengeString
        },
        {
          headers: 
            {'Authorization': `Bearer ${token}`}
        })
        .then(function (response) {
          setChallengeResponse(response.data.response)
        })
        .catch(function (error) {
          console.error(error)
        })
      }
    

    return (
    <>
        {/* <h2 style={{display:'flex', justifyContent:'center', marginBottom:'5px'}}>Edit License</h2> */}
        <Grid container direction='column' justifyContent='space-evenly' alignItems='center'>
                    <Grid item>
                      <h2>{clickedId ? `${clickedId.descr} : ${clickedId.id}`: ''}</h2>
                      <h4>{`Last Modified: ${clickedId.last_modified !== undefined ? new Date(clickedId.last_modified * 1000).toLocaleString() : ''}`}</h4>
                    </Grid>
                    <Grid item container direction='row' justifyContent='space-evenly'>
                      <Grid item style={{marginRight:'65px'}}>
                        <Grid item>
                          <h2>Machine Identification</h2>
                        </Grid>
                        <Grid item style={{marginBottom:'10px'}}>
                          <TextField type='input' onChange={handleDescrChange} label='Name (Required)' defaultValue={clickedId.descr} />
                        </Grid>
                        <Grid item style={{marginBottom:'10px'}}>
                          <TextField type='input' onChange={handleZendOneChange} label='SystemID (Required)' defaultValue={clickedId.zend_id}/>   
                        </Grid>        
                        <Grid item style={{marginBottom:'10px'}}>
                          <TextField type='input' onChange={handleZendTwoChange} label='SystemID2 (Optional)' defaultValue={clickedId.zend_id2}/>
                        </Grid>
                        <Grid item>
                          <Grid item style={{marginTop:'75px'}}>
                            <h3>Reset System Passwords</h3>
                          </Grid>
                          <Grid item>
                            <TextField type='input' onChange={handleChallengeChange} label='Challenge:' />
                          </Grid>
                          <Grid item>
                            <div style={{marginTop:'5px'}}>Response: {challengeResponse}</div>
                          </Grid>
                          <Grid item style={{marginTop:'5px', marginBottom:'5px'}}>
                            <Button onClick={handleResetOne} variant='contained'>Get Reset Response from System-ID 1</Button>
                          </Grid>
                          <Grid item>
                            <Button onClick={handleResetTwo} variant='contained'>Get Reset Response from System-ID 2</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      
                      <Grid item>
                        <Grid item>
                          <h2>Product Information</h2>
                        </Grid>
                        <Grid item>
                          <FormControlLabel control={<Checkbox checked={liteChecked} onChange={handleLiteChecked}/>} label='Express?' />
                        </Grid>
                        { liteChecked ?
                        <></>
                        :
                        <>
                        <Grid item>
                          <FormControlLabel control={<Checkbox checked={devicesChecked} onChange={handledevicesChecked}/>} label='Devices'/>
                        </Grid>
                        <Grid item>
                          <TextField type='input' onChange={handleDevicesChange} label='Max' defaultValue={clickedId.devices}/>
                        </Grid>
                        <Grid item>
                          <FormControlLabel control={<Checkbox checked={spansChecked} onChange={handlespansChecked}/>}  label='Hardware Spans'/>
                        </Grid>
                        <Grid item>
                          <TextField type='input' onChange={handleSpansChange} label='Max' defaultValue={clickedId.spans}/>
                        </Grid>
                        <Grid item>
                          <FormControlLabel control={<Checkbox checked={nonSipRoomsChecked} onChange={handlenonSipRoomsChecked}/>} label='Non-SIP Rooms'/>
                        </Grid>
                        <Grid item>
                          <TextField type='input' onChange={handleNonSipRoomsChange} label='Max' defaultValue={clickedId.nonsiprooms}/>
                        </Grid>
                        </>
                        
                        }
                        <Grid item>
                          <FormControlLabel control={<Checkbox checked={browserConsolesChecked} onChange={handlebrowserConsolesChecked}/>} label='BrowserConsoles'/>
                        </Grid>
                        <Grid item>
                          <TextField type='input' onChange={handleBrowserconsolesChange} label='Max Connections' defaultValue={clickedId.browser_consoles}/>
                        </Grid>
                        <Grid item>
                          <p style={{fontWeight:'bold'}}>SWA Date: {isNaN(clickedId.swa) ?  'None' : date.toLocaleDateString()}</p>
                          <TextField onChange={handleSwaChange} type='date' defaultValue={valueDate} />
                        </Grid>
                        <Grid item style={{marginTop:'10px'}}>
                            <Button onClick={saveLicenseClick} variant='contained' style={{marginRight:'10px'}}>Save License</Button>
                            <Button onClick={deleteClick} variant='contained' style={{marginRight:'10px'}}>Delete this License</Button>
                            <Button onClick={downloadClick} variant='contained' style={{marginRight:'10px'}}>Download this License</Button>
                        </Grid>
                        <Grid item style={{marginTop:'10px', marginBottom:'5px'}}>
                            
                        </Grid>
                      </Grid>
                    </Grid>
                </Grid>
        </>
    )
}

export default EditLicense
