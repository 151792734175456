import React, { useState, useEffect } from 'react';
import './App.css';
import fileDownload from 'js-file-download';
import  SearchBar from '../SearchBar/SearchBar';
import SearchResults  from '../SearchResults/SearchResults';
import Login from '../LoginPage/LoginPage'
import UsersDisplay from '../usersDisplay/UsersDisplay';
import EditLicense from '../EditLicense/EditLicense';
import md5 from 'md5'
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField, 
  FormHelperText, 
  Checkbox,
  FormControlLabel,
  Grid
} from '@mui/material';

import axios from 'axios'
import { url } from '../../config'


const App = () => {

  // LOGIN STATE
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userId, setUserId] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [checkSumUsername, setCheckSumUsername] = useState('')
  const [pwd, setPwd] = useState('')
  const [displayEmail, setDisplayEmail] = useState('')
  const [loggedInUserLevel, setLoggedInUserLevel] = useState(0)
  const [token, setToken] = useState('')

  // SEARCH STATE
  const [searchResults, setSearchResults] = useState([])
  const [noSearchResults, setNoSearchResults] = useState(false)
  const [clickedId, setClickedId] = useState()
  
  // USERS STATE
  const [users, setUsers] = useState([])
  const [usersClicked, setUsersClicked] = useState(false)

  // ADD NEW DIALOG STATE
  const [addNewOpen, setAddNewOpen] = useState(false)
  const [zendOneVal, setZendOneVal] = useState('')
  const [zendOne, setZendOne] = useState('')
  const [isDupe, setIsDupe] = useState(false)
  const [zendTwo, setZendTwo] = useState('')
  const [descr, setDescr] = useState('')
  const [devicesNum, setdevicesNum] = useState(0)
  const [spansNum, setSpansNum] = useState(0)
  const [nonSipRoomsNum, setNonSipRoomsNum] = useState(0)
  const [swa, setSwa] = useState()
  const [browserconsolesNum, setBrowserconsolesNum] = useState(0)
  const [liteChecked, setLiteChecked] = useState(false)

  // EDIT LICENSE STATE
  const [editLicenseOpen, setEditLicenseOpen] = useState(false)

  // EDIT USER STATE
  const [editUserOpen, setEditUserOpen] = useState(false)
  const [editUserFullName, setEditUserFullName] = useState()
  const [editUserEmail, setEditUserEmail] = useState()
  const [editUserPassword, setEditUserPassword] = useState()

  //const [licenseName, setLicenseName] = useState('')
  //const [licenseDetails, setLicenseDetails] = useState([])


  
  // Check SystemId and make sure that there are no dupes
  useEffect(() => {
    const timer = setTimeout(async () => {
      setZendOne(zendOneVal)
      //console.log(input)
      if (zendOne !== '' && addNewOpen) {
          await axios.get(`${url}/api/license/dupe/${zendOne}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
          .then(function (response) {
            //console.log(response)
            if (response.data.count > Number(0)) {
              setIsDupe(true)
            } else {
              setIsDupe(false)
            }
          })
          .catch(function (error) {
            console.error(error)
          })
      } else if (zendOne === '') { setIsDupe(false) }
  }, 500)

  return () => {
      clearTimeout(timer)
  }
  })


  const search = async (input) => {
    let resultsArray = []

     if (!resultsArray.includes(input) && input !== '') {
      await axios.get(
        `${url}/api/license/find/${input}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
      .then(function (response) {
        //console.log(response.data)
        if (response.data.length > 0) {
          //console.log(response.data)
          // resultsArray = response.data.map((res) => {
          //   console.log(res.id)
          //if (clickedId) {setClickedId()}
          //   return res
          // })
          setSearchResults(response.data.map((res) => {
            //console.log(res)
            return res
          }))
          //console.log(resultsArray)
          //setSearchResults([...resultsArray])
          //console.log(searchResults)
          setNoSearchResults(false)
        }
      })
      .catch(function (error) {
        console.error(error)
        if (String(error).includes('404')) {
          setNoSearchResults(true)
        }
        setSearchResults([])
      })
      //console.log(searchResults)
    }
    
  }

  const licenseClick = () => {
    setUsersClicked(false)
    setEditLicenseOpen(false)
  }

  const getUsers = async () => {
    await axios.get(`${url}/api/user`, {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then(function (response) {
      //console.log(response)
      setUsersClicked(true)
      //setClickedId('')
      setUsers(response.data)
      setEditLicenseOpen(false)
    })
    .catch(function (error) {
      console.error(error)
      alert('Unable to populate lis of users, please try again')
    })
  } 

  const logOut = () => {
    setToken('')
    setIsLoggedIn(false)
    setSearchResults([])
    setDisplayName('')
    setDisplayEmail('')
    setClickedId('')
    setUsers([])
    setLiteChecked(false)
    setUsersClicked(false)
    setPwd('')
    setEditUserEmail('')
    setEditUserFullName('')
    setEditUserPassword('')
    setEditLicenseOpen(false)
  }

  const exportCSV = async () => {
    await axios.get(`${url}/api/license/csv`, {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then(function (response) {
      //console.log(response.data)
      fileDownload(response.data, 'licenses.csv')
    })
    .catch(function (error) {
      console.error(error)
    })
  }

  //-------------------- Add New Dialog --------------------//
  const handleAddNewClose = () => {
    setAddNewOpen(false)
    setLiteChecked(false)
  }

  const handleZendOneChange = (e) => {
    setZendOneVal(e.target.value)
  }

  const handleZendTwoChange = (e) => {
    setZendTwo(e.target.value)
  }

  const handleDescrChange = (e) => {
    setDescr(e.target.value)
  }

  const handleDevicesChange = (e) => {
    setdevicesNum(e.target.value)
  }

  const handleSpansChange = (e) => {
    setSpansNum(e.target.value)
  }

  const handleNonSipRoomsChange = (e) => {
    setNonSipRoomsNum(e.target.value)
  }

  const handleBrowserconsolesChange = (e) => {
    setBrowserconsolesNum(e.target.value)
  }

  const handleSwaChange = (e) => {
    setSwa(e.target.value)
  }
  
  const handleCheckBoxChange = () => {
    setLiteChecked(!liteChecked)
  }

  const handleAddNewSubmitClick = async () => {
    //console.log('Well shit')
    // let formData = {
    //   zid: zendOne,
    //   zid2: zendTwo,
    //   desc: descr,
    //   devicesNum: Number(devicesNum),
    //   spansNum: Number(spansNum),
    //   nonSipRmNum: Number(nonSipRoomsNum),
    //   browserconsoles: Number(browserconsolesNum),
    //   swaDate: Date(swa),
    //   isLite: liteChecked
    // }   
    // console.log(formData)

    if (liteChecked) {
      if (
        zendOne === ''
        || !swa
      ) { return (alert('Must have a System Id and Date, please try again'))}
    } else {
      if (
        zendOne === ''
        ||!swa
      ) {return (alert('Must have a System Id and Date, Please try again'))}
    }


    await axios.post(`${url}/api/license`, { //Data
        zend_id: zendOne,
        zend_id2: zendTwo,
        descr: descr,
        devices: Number(devicesNum),
        spans: Number(spansNum),
        nonsiprooms: Number(nonSipRoomsNum),
        browserconsoles: Number(browserconsolesNum),
        swa: Date.parse(swa)/1000,
        lite: liteChecked
    },
    {headers: { //Headers are optional in a POST request, I guess.
      'Authorization': `Bearer ${token}`
    }})
    .then(function (response) {
      console.log(response)
      return response
    })
    .catch(function (error) {
      console.error(error)
      if (String(error).includes('500')) {
        alert('There was an internal sever error try again later')
      }
    })

    handleAddNewClose()
    setLiteChecked(false)
  }

  const handleEditUser = async() => {
    await axios.get(`${url}/api/user/${Number(userId)}`, {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then(function (response) {
      //console.log(response.data)
      setEditUserEmail(response.data[0].email)
      setEditUserFullName(response.data[0].fullname)
      setEditUserOpen(true)
    })
    .catch(function (error) {
      console.error(error)
      alert('An Error occurred while getting user. Please try again')
    })
        
  }

  const handleEditUserClose = () => {
    setEditUserOpen(false)
    setEditUserEmail('')
    setEditUserFullName('')
    setEditUserPassword('')
  }
  
  const handleEditUserFullName = (e) => {
    setEditUserFullName(e.target.value)
  }

  const handleEditUserEmail = (e) => {
    setEditUserEmail(e.target.value)
  }

  const handleEditUserPassword = (e) => {
    setEditUserPassword(e.target.value)
  }

  const handleEditUserSubmit = async() => {
    if (editUserPassword !== pwd) {
      alert('Password has been changed')
    }
    await axios.put(`${url}/api/user/${userId}`, {
      id: Number(userId),
      fullname: editUserFullName,
      email: editUserEmail,
      password: md5(editUserPassword)
    },
    {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then(function (response) {
      //console.log(response)
      handleEditUserClose()
      return response
    })
    .then(function (error) {
      console.error(error)
      //alert('There was an error submiting. Try again')
    })
  }

  const mainPage = (
      <>
        <div className='Head'>
          <h1 style={{marginBottom:'0px', marginTop:'2px'}}>Voiceware Licensing System</h1>
          <div>
            {displayName !== '' ? <div><h3>{`${displayName} : ${displayEmail}`}</h3></div> : <></>}
          </div>
          <div className='ButtonGroup'>
              {
              editLicenseOpen
              ?
              <>
                <Button onClick={licenseClick}>License</Button>
                <Button onClick={getUsers}>Users</Button>
              </>
              :
              <>
              {
              usersClicked 
              ? 
              <Button onClick={licenseClick}>License</Button> 
              :
              <>{loggedInUserLevel > 1 ? <Button onClick={getUsers}>Users</Button> : <Button onClick={handleEditUser}>Edit User</Button>}</>
              }
              </>
              }
              {loggedInUserLevel === 2 ? <Button onClick={exportCSV}>CSV Export</Button> : <></>} 
              <Button onClick={logOut}>Log Out</Button>
          </div>
        </div>
        <Dialog open={addNewOpen} onClose={handleAddNewClose}>
          <DialogTitle style={{display:'flex', justifyContent:'center', background:'#282c34', color:'white', marginBottom:'5px'}}>
            Add New License
          </DialogTitle>
          <DialogContent>
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px', marginTop:'10px'}}>
                <div>
                <TextField
                  id='zendId1'
                  label='System Id'
                  type='input'
                  onChange={handleZendOneChange}
                />
                {isDupe ? <div style={{display: 'flex', justifyContent:'center',color:'red'}}>System Id already exists. Choose Another</div> : <></>}
                </div>
              </div>
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px', marginTop:'10px'}}>
                <TextField
                  id='zendId2'
                  label='System Id 2'
                  type='input'
                  onChange={handleZendTwoChange} 
                />
              </div>
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                <TextField
                  id='descr'
                  label='Description'
                  type='input'
                  onChange={handleDescrChange} 
                />
              </div>
              {liteChecked ? <></> : <><div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                <TextField
                  id='devices'
                  label='# of Devices'
                  type='input'
                  onChange={handleDevicesChange} 
                />
              </div>
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                <TextField
                  id='spans'
                  label='# of Spans'
                  type='input'
                  onChange={handleSpansChange} 
                />
              </div>
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                <TextField
                  id='nonSipRooms'
                  label='# of Non Sip Rooms'
                  type='input'
                  onChange={handleNonSipRoomsChange} 
                />
              </div></>}
              <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                  <TextField
                    id='browserConsoles'
                    label='# of Browser Consoles'
                    type='input'
                    onChange={handleBrowserconsolesChange} 
                  />
                </div>
                <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                  <FormHelperText style={{display:'flex', justifyContent:'center', marginTop:'10px', fontWeight:'bold'}}>SWA DATE</FormHelperText>
                </div>
                <div style={{display:'flex', justifyContent:'center', marginBottom:'10px'}}>
                  <TextField
                    id='swa'
                    label=''
                    type='date'
                    onChange={handleSwaChange} 
                  />
                </div>
              <FormControlLabel
                label='Express'
                control={
                  <Checkbox onChange={handleCheckBoxChange} />
                } 
                style={{display: 'flex', justifyContent:'center'}}
              />
            <div style={{display:'flex', justifyContent:'center'}}>
              <Button disabled={isDupe ? true : false} variant='contained'onClick={handleAddNewSubmitClick}>Submit</Button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={editUserOpen} onClose={handleEditUserClose}>
                <DialogTitle style={{display:'flex', justifyContent:'center', background:'#282c34', color:'white'}}>Edit User</DialogTitle>
                <DialogContent>
                  <Grid container direction='column'>
                    <Grid item style={{width:'100%', display:'flex', justifyContent:'center'}}>
                      <h4>{`Username: ${checkSumUsername}`}</h4>
                    </Grid>
                    <Grid item style={{marginBottom:'10px'}}>
                      <TextField onChange={handleEditUserFullName} type='input' label='Full Name' defaultValue={editUserFullName}/>
                    </Grid>
                    <Grid item style={{marginBottom:'10px'}}>
                      <TextField onChange={handleEditUserEmail} type='input' label='Email' defaultValue={editUserEmail}/>
                    </Grid>
                    <Grid item style={{marginBottom:'10px'}}>
                      <TextField onChange={handleEditUserPassword} type='Password' label='Password' defaultValue={pwd}/>
                    </Grid>
                    <Grid item style={{display:'flex', justifyContent:'space-evenly'}}>
                      <Button onClick={handleEditUserSubmit} variant='contained'>Save</Button>
                      <Button onClick={handleEditUserClose} variant='contained'>Cancel</Button>
                    </Grid>
                  </Grid>
                </DialogContent>
        </Dialog>
        { 
        usersClicked || editLicenseOpen
        ? 
        <></> 
        : 
        <div className='Search'>
          <SearchBar
          onSearch={search}
          addNew={setAddNewOpen}
          />
        </div>
        }
        {
          <div>
            {
            editLicenseOpen
            ?
            <EditLicense clickedId={clickedId} setClickedId={setClickedId} token={token} setEditLicenseOpen={setEditLicenseOpen} setSearchResults={setSearchResults}/>
            :
            <div>
              {
              usersClicked 
              ? 
              <UsersDisplay setDisplayName={setDisplayName} userLevel={loggedInUserLevel} updateUsers={setUsers} usersList={users} token={token} checkSumUsername={checkSumUsername}/> 
              : 
              <>
              {
              noSearchResults 
              ? 
              <div style={{display:'flex', justifyContent:'center'}}>No results Found</div> 
              : 
              <SearchResults results={searchResults} setClickedId={setClickedId} setEditLicenseOpen={setEditLicenseOpen} />
              }
              </>
              }
            </div>
            }
          </div>
        }
      </>
  )


  return (
    <>
      {isLoggedIn 
      ? 
      mainPage 
      : 
      <Login 
      displayName={setDisplayName} 
      displayEmail={setDisplayEmail} 
      loggedIn={setIsLoggedIn} 
      userId={setUserId}
      setToken={setToken}
      setLoggedInUserLevel={setLoggedInUserLevel}
      setCheckSumUsername={setCheckSumUsername}
      pwdForEdit={setPwd}
      />
      }
    </>
  )
}

export default App;



