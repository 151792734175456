import React, { useState, useEffect } from 'react'
import './searchBar.css'
import { TextField, Button } from '@mui/material'



const SearchBar = (props) => {

    const [value, setValue] = useState('')
    const [previousValue, setPreviousValue] = useState('')
    const [input, setInput] = useState('')

    useEffect(() => {
        const timer = setTimeout(async () => {
            setInput(value)
            //console.log(input)
            if (input !== '') {
                setPreviousValue(input)
                if(previousValue !== value) {
                    props.onSearch(input)
                }
            }
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [setInput, input, previousValue, value, props])

    const getSearchValue = (e) => {
        //console.log(e.target.value)
        setValue(e.target.value)
    }

    const addNewClick = () => {
        // Logic for adding new user/license/whatever
        props.addNew(true)
    }

    return (
        <>
            <TextField
                className='SearchField'
                label='Search by description, name, or systemId'
                type='search'
                onChange={getSearchValue}
            />
            <div>
                <Button 
                    style={{marginLeft: '10px'}} 
                    variant='contained' 
                    disableElevation 
                    className='SearchButton'
                    onClick={addNewClick}
                >
                    Add New
                </Button>
            </div>
        </>
    )
}

export default SearchBar

